const Circle = class {
  kakao = undefined;
  map = undefined;
  circle = undefined;
  key = undefined;
  circleInfo = undefined;
  properties = {};
  _visible = false;

  constructor(kakao, map, args) {
    this.kakao = kakao;
    this.map = map;

    const { key, circleInfo, properties } = args;

    this.key = key;

    this.circleInfo = circleInfo;

    this.properties = properties || {};

    this.createCircle();

    if (typeof circleInfo.visible !== 'undefined') {
      this.visible = circleInfo.visible;
    }
  }

  destroy() {
    this.visible = false;
  }

  getLatLng() {
    const latLng = this.circle.getPosition();
    const lat = latLng.getLat();
    const lng = latLng.getLng();

    return { lat, lng };
  }

  setLatLng({ lat, lng }) {
    let { lat: _lat, lng: _lng } = this.getLatLng();

    if (typeof lat !== 'undefined') {
      _lat = lat;
    }
    if (typeof lng !== 'undefined') {
      _lng = lng;
    }
    const latLng = new this.kakao.maps.LatLng(_lat, _lng);
    this.circle.setPosition(latLng);
  }

  set click(callback) {
    const me = this;
    const { kakao } = this;

    if (this.onClick) {
      kakao.maps.event.removeListener(this.circle, 'click', this.onClick);
    }
    const { lat, lng } = this.getLatLng();
    this.onClick = () => {
      callback(me.properties, { lat, lng });
    };
    kakao.maps.event.addListener(this.circle, 'click', this.onClick);
  }

  get visible() {
    return this._visible;
  }

  set visible(value) {
    if (this._visible === value) {
      return;
    }

    if (value === true) {
      this.circle.setMap(this.map);
      this._visible = true;
    } else if (value === false) {
      this.circle.setMap(null);
      this._visible = false;
    }
  }

  createCircle() {
    const { kakao } = this;

    const {
      radius, // 미터 단위의 원의 반지름입니다
      strokeWeight = 1, // 선의 두께입니다
      strokeColor = '#75B8FA', // 선의 색깔입니다
      strokeOpacity = 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
      strokeStyle, // 선의 스타일 입니다
      fillColor = '#CFE7FF', // 채우기 색깔입니다
      fillOpacity = 0.5, // 채우기 불투명도 입니다
      zIndex,
      lat,
      lng,
      // visible = true,
    } = this.circleInfo;

    // const { lat, lng } = this.getLatLng();
    const circle = new kakao.maps.Circle({
      center: new kakao.maps.LatLng(lat, lng), // 원의 중심좌표 입니다
      radius,
      strokeWeight,
      strokeColor,
      strokeOpacity,
      strokeStyle,
      fillColor,
      fillOpacity,
      zIndex,
    });

    this.circle = circle;
  }
};

const CircleLayer = class {
  circleList = [];

  constructor(args) {
    const { kakao, mapObject } = args;
    this.kakao = kakao;
    this.mapObject = mapObject;
  }

  addCircle(args, idx = -1) {
    const { key } = args;

    const circle = new Circle(this.kakao, this.mapObject, args);

    if (idx < 0) {
      this.circleList.push({
        key,
        circle,
      });
    } else {
      this.circleList[idx] = {
        key,
        circle,
      };
    }
  }

  addCircleList(argsList = []) {
    const me = this;
    argsList.forEach((args) => {
      me.addCircle(args);
    });
  }

  removeCircle(key) {
    const idx = this.circleList.findIndex((circle = {}) => circle.key === key);
    const circleInfo = this.circleList[idx];
    if (circleInfo) {
      const { circle } = circleInfo;
      circle.destroy();
    }
    this.circleList[idx] = undefined;

    return idx;
  }

  removeAllCircle() {
    this.circleList.forEach((circleInfo) => {
      if (!circleInfo) return;
      const { circle } = circleInfo;
      circle.destroy();
    });

    this.circleList = [];
  }

  changeCircle(key, newInfo) {
    const idx = this.removeCircle(key);
    this.addCircle(newInfo, idx);
  }

  findCircle(key) {
    return this.circleList.find((circleInfo = {}) => circleInfo.key === key);
  }
};

export { CircleLayer, Circle };
