const events = [
  'click',
  'rightclick',
  'dblclick',
  'mouseup',
  'mousedown',
  'mouseover',
  'mouseout'
]

export default {
  name: 'place',
  template: "<div></div>",
  created() {
      this.$events = events
  },    
  props : {
      mapObject: {
          
      },
      geometry: {
          type: Object
      },
      properties: {
          type: Object
      },
      dataObj: {
          type: Object
      },
      clickable: {
          type: Boolean,
          default: true
      },
      label: {
          
      },
      visible: {
          default: true
      },
      alwaysActivate: {
          default: false
      },
      markerInfoWindow: {
          default: true
      }
  },
  data() {
      return {
        
      }
  }

}