const Marker = class {
  kakao = undefined;
  map = undefined;
  marker = undefined;
  lat = undefined;
  lng = undefined;
  // markerImage = undefined;
  _markerImage = undefined;
  properties = {};

  _visible = false;

  _visibleIw = false;
  infowindow = undefined;
  iwContent = '';

  circleList = [];

  constructor(kakao, map, args) {
    this.kakao = kakao;
    this.map = map;

    const {
      // key,
      lat,
      lng,
      markerImage,
      event = {},
      visible = true,
      infowindow,
      circleList,
      properties,
      draggable = false,
    } = args;

    // this._lat = lat;
    // this._lng = lng;
    if (markerImage) {
      this.markerImage = markerImage;
    } else {
      this.markerImage = {
        src: 'static/images/kakao_default_marker.png',
      };
    }

    this.properties = properties || {};

    this.createMarker(lat, lng);

    for (let eventName in event) {
      // this['set'+eventName](event[eventName]);
      this[eventName] = event[eventName];
    }

    this.visible = visible;

    if (infowindow) {
      this.iwContent = infowindow.iwContent || '';
      this.makeInfowindow();
      this.visibleIw = infowindow.visibleIw;
    }

    if (typeof draggable !== 'undefined') {
      this.draggable = draggable;
    }

    if (Array.isArray(circleList) && circleList.length > 0) {
      circleList.forEach((circle) => {
        const c = this.makeCircle(circle);
        this.circleList.push(c);
      });

      this.openCircle();
    }
  }

  destroy() {
    this.circleList.forEach((circle) => circle.setMap(null));
    if (this.infowindow) {
      this.infowindow.setMap(null);
    }
    this.visible = false;
  }

  createMarker(lat, lng) {
    const { kakao } = this;
    const latLng = new kakao.maps.LatLng(lat, lng);

    this.marker = new kakao.maps.Marker({
      position: latLng,
      image: this._markerImage,
    });
  }

  set markerImage(args) {
    const { kakao } = this;
    let { src, size = { width: 24, height: 35 } } = args;

    if (!src) return;
    // if (!src) {
    //   src = 'static/images/kakao_default_marker.png';
    // }

    const imageSize = new kakao.maps.Size(size.width, size.height);

    this._markerImage = new kakao.maps.MarkerImage(src, imageSize /* imageOption */);
  }

  getLatLng() {
    const latLng = this.marker.getPosition();
    const lat = latLng.getLat();
    const lng = latLng.getLng();

    return { lat, lng };
  }
  setLatLng({ lat, lng }) {
    let { lat: _lat, lng: _lng } = this.getLatLng();

    if (typeof lat !== 'undefined') {
      _lat = lat;
    }
    if (typeof lng !== 'undefined') {
      _lng = lng;
    }
    const latLng = new this.kakao.maps.LatLng(_lat, _lng);
    this.marker.setPosition(latLng);
  }

  set click(callback) {
    const me = this;
    const { kakao } = this;

    if (this.onClick) {
      kakao.maps.event.removeListener(this.marker, 'click', this.onClick);
    }
    const { lat, lng } = this.getLatLng();
    this.onClick = () => {
      callback(me.properties, { lat, lng });
    };
    kakao.maps.event.addListener(this.marker, 'click', this.onClick);
  }

  get visible() {
    return this._visible;
  }

  set visible(value) {
    if (this._visible === value) {
      return;
    }

    if (value === true) {
      this.marker.setMap(this.map);
      this._visible = true;
    } else if (value === false) {
      this.marker.setMap(null);
      this._visible = false;
    }
  }

  makeInfowindow() {
    if (!this.iwContent) return;
    else if (this.infowindow) {
      this.infowindow.setMap(null);
    }

    const { kakao } = this;

    // this.infowindow = new kakao.maps.InfoWindow({
    //   // content: this._iwContent,
    //   content: this.iwContent,
    //   // removable : iwRemoveable
    // });

    this.infowindow = new kakao.maps.CustomOverlay({
      map: this.map,
      position: this.marker.getPosition(),
      content: this.iwContent,
      xAnchor: 0.5,
      yAnchor: 1.9,
      zIndex: 15,
    });
  }

  get visibleIw() {
    return this._visibleIw;
  }

  set visibleIw(value) {
    if (!this.infowindow) return;

    if (value === true) {
      // this.infowindow.open(this.map, this.marker);
      this.infowindow.setVisible(true);
      this._visibleIw = true;
    } else if (value === false) {
      // this.infowindow.close();
      this.infowindow.setVisible(false);
      this._visibleIw = false;
    }
  }

  set draggable(args) {
    this.marker.setDraggable(args);
  }

  makeCircle(args) {
    const { kakao } = this;

    const {
      radius, // 미터 단위의 원의 반지름입니다
      strokeWeight = 1, // 선의 두께입니다
      strokeColor = '#75B8FA', // 선의 색깔입니다
      strokeOpacity = 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
      strokeStyle, // 선의 스타일 입니다
      fillColor = '#CFE7FF', // 채우기 색깔입니다
      fillOpacity = 0.5, // 채우기 불투명도 입니다
      zIndex,
    } = args;

    const { lat, lng } = this.getLatLng();
    const circle = new kakao.maps.Circle({
      center: new kakao.maps.LatLng(lat, lng), // 원의 중심좌표 입니다
      radius,
      strokeWeight,
      strokeColor,
      strokeOpacity,
      strokeStyle,
      fillColor,
      fillOpacity,
      zIndex,
    });

    this.circleList.push(circle);

    return circle;
  }

  openCircle(idx) {
    if (typeof idx === 'undefined') {
      this.circleList.forEach((circle) => {
        if (!circle) return;
        circle.setMap(this.map);
      });
    } else {
      const circle = this.circleList[idx];
      if (!circle) return;
      circle.setMap(this.map);
    }
  }

  closeCircle(idx) {
    if (typeof idx === 'undefined') {
      this.circleList.forEach((circle) => {
        if (!circle) return;
        circle.setMap(null);
      });
    } else {
      const circle = this.circleList[idx];
      if (!circle) return;
      circle.setMap(null);
    }
  }
};

const MarkerLayer = class {
  markerList = [];

  constructor(args) {
    const { kakao, mapObject } = args;
    this.kakao = kakao;
    this.mapObject = mapObject;
  }

  addMarker(args, idx = -1) {
    const { key } = args;

    const marker = new Marker(this.kakao, this.mapObject, args);

    if (idx < 0) {
      this.markerList.push({
        key,
        marker,
      });
    } else {
      this.markerList[idx] = {
        key,
        marker,
      };
    }
  }

  addMarkerList(argsList = []) {
    const me = this;
    argsList.forEach((args) => {
      me.addMarker(args);
    });
  }

  removeMarker(key) {
    const idx = this.markerList.findIndex((markerInfo = {}) => markerInfo.key === key);
    const markerInfo = this.markerList[idx];
    if (markerInfo) {
      const { marker } = markerInfo;
      marker.destroy();
    }
    this.markerList[idx] = undefined;

    return idx;
  }

  removeAllMarker() {
    this.markerList.forEach((markerInfo) => {
      if (!markerInfo) return;
      const { marker } = markerInfo;
      marker.destroy();
    });

    this.markerList = [];
  }

  changeMarker(key, newInfo) {
    const idx = this.removeMarker(key);
    this.addMarker(newInfo, idx);
  }

  findMarker(key) {
    return this.markerList.find((markerInfo = {}) => markerInfo.key === key);
  }

  get markerList() {
    return this.markerList;
  }
};

export { MarkerLayer, Marker };
