<template>
  <div ref="content" class="marker-select-box-info" :style="`z-index: 10000; width: ${width}px; height:${height}px`">
    <div class="marker-select-box">
      <div class="marker-select-element" v-for="markerInfo in selectBoxData.markerList" :key="markerInfo.key" @click="selectMarker(markerInfo)">
        <strong>{{ markerInfo.name || markerInfo.key }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mapObject: {
      required: true,
    },
    selectBoxData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    clusterOverlay: undefined,
    width: 200,
    height: 90,
  }),
  created() {
    const { markerList = [] } = this.selectBoxData;
    this.height = markerList.length * 30;
    let width = 0;
    markerList.forEach((markerInfo) => {
      const str = markerInfo.name.length || markerInfo.key.length;
      const len = str * 10;
      if (width < len) {
        width = len;
      }
    });
    // this.width = width + 30;
    this.width = width * 1.3;
  },
  destroyed() {
    this.removeCustomOverlay();
  },
  mounted() {
    this.createCustomOverlay();
  },
  methods: {
    createCustomOverlay() {
      const { geometry } = this.selectBoxData;
      this.removeCustomOverlay();

      const position = new kakao.maps.LatLng(geometry.lat, geometry.lng);

      const content = this.$refs.content;

      this.clusterOverlay = new kakao.maps.CustomOverlay({
        map: this.mapObject,
        position,
        content,
        xAnchor: 0.5,
        yAnchor: 1,
        clickable: true,
        zIndex: 1000,
      });
    },

    removeCustomOverlay() {
      if (this.clusterOverlay) {
        this.clusterOverlay.setMap(null);
      }
    },

    selectMarker(markerInfo) {
      // this.$emit('selectMarker', 1);
      // this.$parent.$emit('selectMarker', 2);
      this.$parent.$parent.$emit('selectMarker', markerInfo);
    },
  },
};
</script>
