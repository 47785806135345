<template>
  <div ref="placeTag" class="place-marker">{{ properties.addressName }}</div>
</template>

<script>
import dkplace from '../dkGisPlace';
import axios from 'axios';

export default {
  mixins: [dkplace],
  data() {
    return {
      placeOverlay: null,
      overlayPolygon: [],
    };
  },
  created() {},
  mounted() {
    // 커스텀 오버레이가 표시될 위치입니다
    var position = new kakao.maps.LatLng(this.geometry.lat, this.geometry.lng);
    var content = this.$refs.placeTag;

    // 커스텀 오버레이를 생성합니다
    this.placeOverlay = new kakao.maps.CustomOverlay({
      map: this.mapObject,
      position: position,
      content: content,
      xAnchor: 1,
      yAnchor: 1,
    });

    this.overlayPolygon = new kakao.maps.Polygon({
      map: this.mapObject,
      path: [],
      strokeWeight: 2,
      strokeColor: '#47b6e4',
      strokeOpacity: 0.8,
      strokeStyle: 'solid',
      fillColor: '#47b6e4',
      fillOpacity: 0.4,
    });
    this.dataObj.$focusPlace = this.focusPlace;
    this.dataObj.$blurPlace = this.blurPlace;
  },
  destroyed() {
    this.placeOverlay.setMap(null);
    this.placeOverlay = null;

    this.overlayPolygon.setMap(null);
    this.overlayPolygon = null;
  },
  methods: {
    placeClick(ev) {
      let me = this;
      me.$emit('click', {
        event: ev || null,
        geometry: me.geometry,
        properties: me.properties,
        object: me,
      });
    },
    focusPlace() {
      let geojsonFile = `/geojson/${this.properties.clusterCd}.geojson`;
      let polygonPath = [];
      axios.get(geojsonFile).then(({ data }) => {
        if (data.type == 'MultiPolygon') {
          data.coordinates[0][0].forEach((path) => {
            polygonPath.push(new kakao.maps.LatLng(path[1], path[0]));
          });
        }
        this.overlayPolygon.setMap(null);
        this.overlayPolygon.setPath(polygonPath);
        this.overlayPolygon.setMap(this.mapObject);
      });
    },
    blurPlace() {
      this.overlayPolygon.setMap(null);
    },
  },
};
</script>
