///////////////////////////////////////////////////////////////////
/*
/* 카카오 GIS 메인 모듈
/* 
/* 카카오는 줌 레벨체계가 DK GIS와 동일한 1:1임
/*
/////////////////////////////////////////////////////////////////*/

import dkcoord from '../dkGisCoord';
import dkMap from '../dkGisMap';
import marker from './marker.vue';
import cluster from './cluster.vue';
import place from './place.vue';
import { MarkerLayer } from './markerLayer';
import { CircleLayer } from './circleLayer';
import selectBox from './selectBox.vue';

export default {
  mixins: [dkMap],
  components: {
    'dk-marker': marker,
    'dk-cluster': cluster,
    'dk-search-place': place,
    'select-box': selectBox,
  },
  data() {
    return {
      isRoadview: false,
      roadviewObject: null,
      roadviewClient: null,
      roadviewValue: {},
      beforeSelectedMarker: null,
      areaManager: {},
      geolocationPolygon: null,
      geolocationMarker: null,
      centerPointMarker: null,

      lineData: {
        lineDrawingFlag: false,
        nowLineDrawing: false,
        clickLine: undefined,
        moveLine: undefined,
        distanceOverlay: undefined,
        dots: [],
        distanceMarkers: [],
        getHtml: undefined,
      },

      kakaoDefaultMarkerPath: 'static/images/kakao_default_marker.png',
    };
  },
  created() {
    // GIS상에 시설물 추가 이벤트 정의
    this.$fcltManageOnGis = (ev) => {
      this.$el.children[0].children[0].className = '';
      // this.$el.children[0].className="vue-dkgis-container"
      kakao.maps.event.removeListener(this.mapObject, 'click', this.$fcltManageOnGis);
      if (this.$refs.fcltMarker) {
        this.$refs.fcltMarker.forEach((marker) => {
          marker.setEventBind();
        });
      }
      if (this.$config.getServerConfig().core.isClosedWeb) {
        return this.$parent.$emit('markerDragEnd', {
          geometry: {
            lat: ev.latLng.getLat(),
            lng: ev.latLng.getLng(),
          },
          addr: null,
        });
      }
      return new Promise((resolve, reject) => {
        this.$geocoder.coord2Address(ev.latLng.getLng(), ev.latLng.getLat(), (result, status) => {
          let addr = {
            road_addr: '주소 정보 없음',
            num_addr: '주소 정보 없음',
          };
          if (result.length > 0) {
            addr.road_addr = result[0].road_address ? result[0].road_address.address_name : '주소 정보 없음';
            addr.num_addr = result[0].address ? result[0].address.address_name : '주소 정보 없음';
          }

          return resolve(addr);
        });
      }).then((addr) => {
        this.$parent.$emit('fcltManagerOnGis', {
          geometry: {
            lat: ev.latLng.getLat(),
            lng: ev.latLng.getLng(),
          },
          addr: addr,
        });
      });
    };

    // 시설물 마커 드래그end 이벤트 정의
    this.$on('markerDragEnd', (ev) => {
      if (this.$config.getServerConfig().core.isClosedWeb) {
        return this.$parent.$emit('markerDragEnd', {
          geometry: {
            lat: ev.latLng.getLat(),
            lng: ev.latLng.getLng(),
          },
          addr: null,
        });
      }
      this.$geocoder.coord2Address(ev.latLng.getLng(), ev.latLng.getLat(), (result, status) => {
        let addr = {
          road_addr: '주소 정보 없음',
          num_addr: '주소 정보 없음',
        };
        if (result.length > 0) {
          addr.road_addr = result[0].road_address ? result[0].road_address.address_name : '주소 정보 없음';
          addr.num_addr = result[0].address ? result[0].address.address_name : '주소 정보 없음';
        }

        this.$parent.$emit('markerDragEnd', {
          geometry: {
            lat: ev.latLng.getLat(),
            lng: ev.latLng.getLng(),
          },
          addr: addr,
        });
      });
    });
  },
  mounted() {
    console.log('kakao map mounted');
    let me = this;
    const element = this.$refs['vueDkgisContainer'];
    console.log(JSON.stringify(this.gisApiInfo.geometry));
    // 카카오 GIS 초기화;
    let center = new kakao.maps.LatLng(this.gisApiInfo.geometry.lat, this.gisApiInfo.geometry.lng);
    this.mapObject = new kakao.maps.Map(element, {
      center: center,
      level: this.getLocalLevel(this.gisApiInfo.level) || 3,
      mapTypeId: kakao.maps.MapTypeId.KAKAO_PROXY, //프록시
    });

    try {
      let areaOptions = {
        map: me.mapObject,
        drwingMode: [kakao.maps.drawing.OverlayType.CIRCLE],
        guideTooltip: ['draw', 'drag', 'edit'],
        circleOptions: {
          draggable: true,
          removable: true,
          editable: true,
          strokeColor: '#39f',
          fillColor: '#39f',
          fillOpacity: 0.5,
        },
      };
      this.areaManager = new kakao.maps.drawing.DrawingManager(areaOptions);
    } catch (err) {
      console.log(err);
    }
    // try{
    // this.clusterer = new kakao.maps.MarkerClusterer({
    //     map : me.mapObject,
    //     averageCenter : true,
    //     minLevel : 4,
    // })
    // let clusterermarker = this.positions.map((position)=>{
    //     return new kakao.maps.Marker({
    //         position : new kakao.maps.LatLng(position.lat, position.lng)
    //     })
    // })
    // this.clusterer.addMarkers(clusterermarker);
    // }catch(err){
    //     console.log(err)
    // }
    this.$geocoder = new kakao.maps.services.Geocoder();

    // 로드뷰 초기화
    const roadviewElement = this.$refs['vueDkgisRoadviewContainer'];
    this.roadviewObject = new kakao.maps.Roadview(roadviewElement);
    this.roadviewClient = new kakao.maps.RoadviewClient();

    this.$parent.$emit('apiLoadComplete', {
      msg: '카카오 로딩 컴플릿',
    });

    kakao.maps.event.addListener(this.mapObject, 'center_changed', () => {
      this.$parent.$emit('centerChanged', {
        level: me.getLevel(),
        geometry: me.getCenter(),
      });
    });

    kakao.maps.event.addListener(this.mapObject, 'dragend', () => {
      this.$parent.$emit('moveEnd', {
        level: me.getLevel(),
        geometry: me.getCenter(),
      });
    });

    kakao.maps.event.addListener(this.mapObject, 'zoom_changed', () => {
      this.$parent.$emit('moveEnd', {
        level: me.getLevel(),
        geometry: me.getCenter(),
      });
      this.$parent.$emit('zoomEnd', {
        level: me.getLevel(),
        geometry: me.getCenter(),
      });
      // else if (me.getLevel() > 6 ) {
      //   me.setLevel(7)
      // }
    });

    kakao.maps.event.addListener(this.mapObject, 'bounds_changed', () => {
      this.$parent.$emit('changedBounds');
    });

    this.centerPointMarker = new kakao.maps.Marker({
      map: this.mapObject,
      position: center,
      zIndex: 0,
      image: new kakao.maps.MarkerImage('static/images/dot-and-circle.png', new kakao.maps.Size(26, 26)),
    });
    this.centerPointMarker.setVisible(false);
  },
  destroyed() {
    console.log('kakao map destroyed');
  },
  methods: {
    getLevel() {
      return this.mapObject.getLevel();
    },
    getLocalLevel(level) {
      return level;
    },
    setLevel(level) {
      this.mapObject.setLevel(level);
    },

    setCenter(coord) {
      let center = new kakao.maps.LatLng(coord.lat, coord.lng);
      this.mapObject.setCenter(center);
    },

    getCenter() {
      var latlng = this.mapObject.getCenter();
      /*   return dkcoord.convertProj("4326", "4326", {
                lat: latlng.getLat(),
                lon: latlng.getLng()
            })*/
      return {
        lat: latlng.getLat(),
        lng: latlng.getLng(),
      };
    },

    clickMarker(ev) {
      if (this.beforeSelectedMarker) {
        this.beforeSelectedMarker.setIconImageByStatus();
      }
      this.beforeSelectedMarker = ev.object;
      this.$parent.$emit('clickMarker', ev);
    },

    clickCluster(ev) {
      let level = this.getLevel();
      if (level >= 4 && level < 8) {
        if (ev.properties.fcltCenterX && ev.properties.fcltCenterY) {
          this.setCenter({
            lng: ev.properties.fcltCenterX,
            lat: ev.properties.fcltCenterY,
          });
        } else {
          this.setCenter(ev.geometry);
        }
        setTimeout(() => {
          this.setLevel(4);
        }, 100);
      } else if (level >= 8) {
        this.setCenter(ev.geometry);
        setTimeout(() => {
          this.setLevel(6);
        }, 100);
      }
      this.$parent.$emit('clickCluster', ev);
    },

    clickPlace(ev) {},
    /**
     *
     * 입력받은 gis좌표로 주소를 얻어온다.
     * kakao서버와 비동기 통신을 해야하므로, 결과값을 promise를 써서 전송한다.
     *
     */
    getAddrFromCoord(coord) {
      let latlng = new kakao.maps.LatLng(coord.lat, coord.lng);
      return new Promise((resolve, reject) => {
        this.$geocoder.coord2Address(latlng.getLng(), latlng.getLat(), (result, status) => {
          let addr = {
            road_addr: '주소 정보 없음',
            num_addr: '주소 정보 없음',
          };
          if (result.length > 0) {
            addr.road_addr = result[0].road_address ? result[0].road_address.address_name : '주소 정보 없음';
            addr.num_addr = result[0].address ? result[0].address.address_name : '주소 정보 없음';
          }

          return resolve(addr);
        });
      }).then((addr) => {
        return {
          geometry: {
            lat: latlng.getLat(),
            lng: latlng.getLng(),
          },
          addr: addr,
        };
      });
    },

    /**
     * 입력 받은 주소값을 이용하여 GIS좌표를 구한다.
     *
     * @param {
     *  addr:String
     * } opts
     *
     * @return [{
     *  addressName:String,
     *  lat:String,
     *  lng:String,
     *  numberAddress:Object,
     *  roadAddress:Object
     * }]
     */
    getCoordFromAddr(addr) {
      return new Promise((resolve, reject) => {
        this.$geocoder.addressSearch(addr, (result, status) => {
          let data = [];
          result.forEach((r) => {
            data.push({
              addressName: r.address_name,
              lat: r.y,
              lng: r.x,
              numberAddress: r.address || {},
              roadAddress: r.road_address || {},
            });
          });
          return resolve(data);
        });
      });
    },

    /**
     *
     * 현재 gis중심좌표로 주소를 얻어온다.
     * kakao서버와 비동기 통신을 해야하므로, 결과값을 promise를 써서 전송한다.
     *
     */
    getAddrFromCenterCoord(centerMarker) {
      var latlng = this.mapObject.getCenter();
      if (centerMarker) {
        this.centerPointMarker.setPosition(latlng), this.centerPointMarker.setVisible(true);
      }

      return this.getAddr(latlng);
    },

    /**
     *
     * gis좌표로 주소를 얻어온다.
     * kakao서버와 비동기 통신을 해야하므로, 결과값을 promise를 써서 전송한다.
     *
     */
    async getAddr(latlng, gis) {
      const me = this;
      let _latlng = latlng;
      if (!_latlng) {
        _latlng = new kakao.maps.LatLng(gis.lat, gis.lng);
      }
      const { result, status } = await new Promise((resolve, reject) => {
        me.$geocoder.coord2Address(_latlng.getLng(), _latlng.getLat(), (result, status) => resolve({ result, status }));
      });

      const addr = {
        road_addr: '주소 정보 없음',
        num_addr: '주소 정보 없음',
      };
      if (result.length > 0) {
        addr.road_addr = result[0].road_address ? result[0].road_address.address_name : '주소 정보 없음';
        addr.num_addr = result[0].address ? result[0].address.address_name : '주소 정보 없음';
      }

      return {
        geometry: {
          lat: _latlng.getLat(),
          lng: _latlng.getLng(),
        },
        addr,
      };
    },

    /**
     * GIS상에서 시설물 관리를 활성화 시킨다
     * 이벤트가 꼬이는걸 방지하기 위해 시설물의 이벤트를 unbind시킨다
     * GIS상에서 클릭이벤트가 한번 실행 되면, 바로 해당 이벤트를 unbind시키고
     * 시설물의 이벤트를 복구하여 다른 버그를 방지한다.
     */
    setFcltManageOnGis(flag) {
      if (!flag) {
        this.$el.children[0].children[0].className = 'fclt-manage-on-gis';
        kakao.maps.event.addListener(this.mapObject, 'click', this.$fcltManageOnGis);
        if (this.$refs.fcltMarker) {
          this.$refs.fcltMarker.forEach((marker) => {
            marker.setUnEventBind();
          });
        }
      } else {
        this.$el.children[0].children[0].className = '';
        kakao.maps.event.removeListener(this.mapObject, 'click', this.$fcltManageOnGis);
        if (this.$refs.fcltMarker) {
          this.$refs.fcltMarker.forEach((marker) => {
            marker.setEventBind();
          });
        }
      }
    },

    setTrafficLayer(flag) {
      if (typeof flag === 'undefind' || typeof flag === 'null' || flag == false) {
        this.mapObject.removeOverlayMapTypeId(kakao.maps.MapTypeId.TRAFFIC);
        return false;
      } else {
        this.mapObject.addOverlayMapTypeId(kakao.maps.MapTypeId.TRAFFIC);
        return true;
      }
    },

    refresh() {
      this.mapObject.relayout();
    },

    setRoadview(coord) {
      let me = this;
      var center = new kakao.maps.LatLng(coord.lat, coord.lng);
      this.roadviewClient.getNearestPanoId(center, 100, function (panoId) {
        if (panoId === null) {
          me.isRoadview = false;
        } else {
          me.roadviewValue.panoId = panoId;
          me.roadviewObject.setPanoId(panoId, center);
          me.roadviewObject.relayout();
          me.isRoadview = true;
        }
      });
    },
    hideRoadview() {
      this.isRoadview = false;
    },
    isShowRoadView() {
      return this.isRoadview;
    },
    selectOverlay() {
      areaManager.cancel();

      areaManager.select(kakao.maps.drawing.areaOptions[type]);
    },

    getFcltCover() {
      //시설물 전체 마커에대한 목록들의 좌표값을통해 원을 그린다.
    },

    // checkFlash() {
    //   var flashAvailable = false;
    //   try {
    //     var flash = new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
    //     if (flash) {
    //       flashAvailable = true;
    //     }
    //   } catch (e) {
    //     if (navigator.mimeTypes['application/x-shockwave-flash'] != undefined) {
    //       flashAvailable = true;
    //     }
    //   } finally {
    //     return flashAvailable;
    //   }
    // },
    getBounds() {
      let bound = this.mapObject.getBounds();
      // 영역의 남서쪽 좌표를 얻어옵니다
      var swLatLng = bound.getSouthWest();
      // 영역의 북동쪽 좌표를 얻어옵니다
      var neLatLng = bound.getNorthEast();
      // var polygon = new kakao.maps.Polygon({
      //     map: this.mapObject,
      //     path: [
      //         bound.getSouthWest(),
      //         bound.getNorthEast()
      //     ],
      //     strokeWeight: 2,
      //     strokeColor: '#FF00FF',
      //     strokeOpacity: 0.8,
      //     strokeStyle: 'dashed',
      //     fillColor: '#00EEEE',
      //     fillOpacity: 0.5
      // });
      return {
        minX: swLatLng.getLng(),
        minY: swLatLng.getLat(),
        maxX: neLatLng.getLng(),
        maxY: neLatLng.getLat(),
      };
    },

    getClusterMarker(args) {
      console.log(this.$refs['cluster']);
    },

    actGeolocation(coord) {
      let latlng = new kakao.maps.LatLng(coord.lat, coord.lng);
      if (this.geolocationPolygon) {
        this.geolocationPolygon.setMap(null);
      }
      if (this.geolocationMarker) {
        this.geolocationMarker.setMap(null);
      }

      return new Promise((resolve, reject) => {
        this.$geocoder.coord2Address(latlng.getLng(), latlng.getLat(), (result, status) => {
          let addr = {
            road_addr: '주소 정보 없음',
            num_addr: '주소 정보 없음',
          };
          if (result.length > 0) {
            addr.road_addr = result[0].road_address ? result[0].road_address.address_name : '주소 정보 없음';
            addr.num_addr = result[0].address ? result[0].address.address_name : '주소 정보 없음';
          }

          return resolve(addr);
        });
      }).then((addr) => {
        if (this.$isMobile) {
          this.geolocationPolygon = new kakao.maps.Circle({
            center: latlng,
            radius: 100,
            strokeWeight: 3,
            strokeColor: '#eb4d4b',
            strokeOpacity: 0.7,
            strokeStyle: 'dashed',
            zIndex: 1,
          });

          const imageSize = new kakao.maps.Size(30, 40);
          const markerImage = new kakao.maps.MarkerImage(this.kakaoDefaultMarkerPath, imageSize /* imageOption */);

          this.geolocationMarker = new kakao.maps.Marker({
            position: latlng,
            zIndex: 2,
            image: markerImage,
          });
          this.geolocationMarker.setMap(this.mapObject);
          this.geolocationPolygon.setMap(this.mapObject);
          this.mapObject.setCenter(latlng);
          this.mapObject.setLevel(3);
        } else {
          //this.mapObject.setCenter(latlng)
        }
        return {
          geometry: {
            lat: latlng.getLat(),
            lng: latlng.getLng(),
          },
          addr: addr,
        };
      });
    },

    setClickEvent(callback) {
      kakao.maps.event.addListener(this.mapObject, 'click', callback);
    },

    freeClickEvent(callback) {
      kakao.maps.event.removeListener(this.mapObject, 'click', callback);
    },

    createMarker(latLng, gis) {
      const imageSize = new kakao.maps.Size(30, 40);
      const markerImage = new kakao.maps.MarkerImage(this.kakaoDefaultMarkerPath, imageSize /* imageOption */);

      const marker = new kakao.maps.Marker({
        position: latLng || new kakao.maps.LatLng(gis.lat, gis.lng),
        zIndex: 2,
        image: markerImage,
      });
      marker.setMap(this.mapObject);
      return marker;
    },

    freeMarker(marker) {
      marker.setMap(null);
    },

    startLineDraw(args = {}) {
      this.lineData.lineDrawingFlag = true;
      this.lineData.getHtml = args.getHtml;
      kakao.maps.event.addListener(this.mapObject, 'click', this.drawingLine);
      kakao.maps.event.addListener(this.mapObject, 'mousemove', this.drawingMoveLine);
    },
    endLineDraw() {
      this.lineData.lineDrawingFlag = false;
      kakao.maps.event.removeListener(this.mapObject, 'click', this.drawingLine);
      kakao.maps.event.removeListener(this.mapObject, 'mousemove', this.drawingMoveLine);
      this.initLine();
    },
    drawingLine(mouseEvent) {
      // 마우스로 클릭한 위치입니다
      const clickPosition = mouseEvent.latLng;

      if (!this.lineData.nowLineDrawing) {
        this.lineData.nowLineDrawing = true;

        // 기존 선 제거
        this.initLine();

        const distanceMarker = this.createMarker(clickPosition);
        this.lineData.distanceMarkers.push(distanceMarker);

        // 클릭한 위치를 기준으로 선을 생성하고 지도위에 표시합니다
        this.lineData.clickLine = this.lineData.clickLine = new kakao.maps.Polyline({
          map: this.mapObject, // 선을 표시할 지도입니다
          path: [clickPosition], // 선을 구성하는 좌표 배열입니다 클릭한 위치를 넣어줍니다
          strokeWeight: 3, // 선의 두께입니다
          strokeColor: '#db4040', // 선의 색깔입니다
          strokeOpacity: 1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
          strokeStyle: 'solid', // 선의 스타일입니다
        });

        // 선이 그려지고 있을 때 마우스 움직임에 따라 선이 그려질 위치를 표시할 선을 생성합니다
        this.lineData.moveLine = new kakao.maps.Polyline({
          strokeWeight: 3, // 선의 두께입니다
          strokeColor: '#db4040', // 선의 색깔입니다
          strokeOpacity: 0.5, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
          strokeStyle: 'solid', // 선의 스타일입니다
        });

        // 클릭한 지점에 대한 정보를 지도에 표시합니다
        this.displayCircleDot(clickPosition, 0);
      } else {
        const distanceMarker = this.createMarker(clickPosition);
        this.lineData.distanceMarkers.push(distanceMarker);

        this.lineData.nowLineDrawing = false;

        // 그려지고 있는 선의 좌표 배열을 얻어옵니다
        const path = this.lineData.clickLine.getPath();

        // 좌표 배열에 클릭한 위치를 추가합니다
        path.push(clickPosition);

        // 다시 선에 좌표 배열을 설정하여 클릭 위치까지 선을 그리도록 설정합니다
        this.lineData.clickLine.setPath(path);

        const distance = Math.round(this.lineData.clickLine.getLength());

        this.displayCircleDot(this.lineData.clickPosition, distance);

        // 마지막 클릭 지점에 대한 거리 정보 커스텀 오버레이를 지웁니다
        if (this.lineData.dots[this.lineData.dots.length - 1].distance) {
          this.lineData.dots[this.lineData.dots.length - 1].distance.setMap(null);
          this.lineData.dots[this.lineData.dots.length - 1].distance = null;
        }

        // const distance = Math.round(this.lineData.clickLine.getLength()); // 선의 총 거리를 계산합니다
        const content = this.getTimeHTML(distance); // 커스텀오버레이에 추가될 내용입니다

        // 그려진 선의 거리정보를 지도에 표시합니다
        this.showDistance(content, path[path.length - 1]);

        // this.endLineDrag();
      }
    },
    drawingMoveLine(mouseEvent) {
      if (!this.lineData.nowLineDrawing) return;

      // 지도 마우스무브 이벤트가 발생했는데 선을 그리고있는 상태이면

      // 마우스 커서의 현재 위치를 얻어옵니다
      const mousePosition = mouseEvent.latLng;

      // 마우스 클릭으로 그려진 선의 좌표 배열을 얻어옵니다
      const path = this.lineData.clickLine.getPath();

      // 마우스 클릭으로 그려진 마지막 좌표와 마우스 커서 위치의 좌표로 선을 표시합니다
      const movepath = [path[path.length - 1], mousePosition];
      this.lineData.moveLine.setPath(movepath);
      this.lineData.moveLine.setMap(this.mapObject);

      const distance = Math.round(this.lineData.clickLine.getLength() + this.lineData.moveLine.getLength()), // 선의 총 거리를 계산합니다
        content = '<div class="dotOverlay distanceInfo">거리 <span class="number">' + distance + '</span>m</div>'; // 커스텀오버레이에 추가될 내용입니다

      // 거리정보를 지도에 표시합니다
      this.showDistance(content, mousePosition);
    },

    displayCircleDot(position, distance) {
      // 클릭 지점을 표시할 빨간 동그라미 커스텀오버레이를 생성합니다
      const circleOverlay = new kakao.maps.CustomOverlay({
        content: '<span class="dot"></span>',
        position: position,
        zIndex: 1,
      });

      // 지도에 표시합니다
      circleOverlay.setMap(this.mapObject);

      let distanceOverlay;
      if (distance > 0) {
        // 클릭한 지점까지의 그려진 선의 총 거리를 표시할 커스텀 오버레이를 생성합니다
        distanceOverlay = new kakao.maps.CustomOverlay({
          content: '<div class="dotOverlay">거리 <span class="number">' + distance + '</span>m</div>',
          position: position,
          yAnchor: 1,
          zIndex: 2,
        });

        // 지도에 표시합니다
        distanceOverlay.setMap(this.mapObject);
      }

      // 배열에 추가합니다
      this.lineData.dots.push({ circle: circleOverlay, distance: distanceOverlay });
    },

    initLine() {
      this.deleteClickLine();
      this.deleteDistnce();
      this.deleteCircleDot();
      this.deleteDistanceMarker();
    },

    deleteClickLine() {
      if (this.lineData.clickLine) {
        this.lineData.clickLine.setMap(null);
        this.lineData.clickLine = null;
      }
      if (this.lineData.moveLine) {
        this.lineData.moveLine.setMap(null);
        this.lineData.moveLine = null;
      }
    },
    deleteDistnce() {
      if (this.lineData.distanceOverlay) {
        this.lineData.distanceOverlay.setMap(null);
        this.lineData.distanceOverlay = null;
      }
    },
    deleteCircleDot() {
      for (let i = 0; i < this.lineData.dots.length; i++) {
        if (this.lineData.dots[i].circle) {
          this.lineData.dots[i].circle.setMap(null);
        }

        if (this.lineData.dots[i].distance) {
          this.lineData.dots[i].distance.setMap(null);
        }
      }

      this.lineData.dots = [];
    },

    deleteDistanceMarker() {
      this.lineData.distanceMarkers.forEach((marker) => {
        marker.setMap(null);
      });
    },

    getTimeHTML(distance) {
      if (this.lineData.getHtml) {
        return this.lineData.getHtml(distance);
      } else {
        return '<div class="dotOverlay">거리 <span class="number">' + distance + '</span>m</div>';
      }

      // // 도보의 시속은 평균 4km/h 이고 도보의 분속은 67m/min입니다
      // const walkkTime = distance / 67 | 0;
      // let walkHour = '', walkMin = '';

      // // 계산한 도보 시간이 60분 보다 크면 시간으로 표시합니다
      // if (walkkTime > 60) {
      //     walkHour = '<span class="number">' + Math.floor(walkkTime / 60) + '</span>시간 '
      // }
      // walkMin = '<span class="number">' + walkkTime % 60 + '</span>분'

      // // 자전거의 평균 시속은 16km/h 이고 이것을 기준으로 자전거의 분속은 267m/min입니다
      // const bycicleTime = distance / 227 | 0;
      // let bycicleHour = '', bycicleMin = '';

      // // 계산한 자전거 시간이 60분 보다 크면 시간으로 표출합니다
      // if (bycicleTime > 60) {
      //     bycicleHour = '<span class="number">' + Math.floor(bycicleTime / 60) + '</span>시간 '
      // }
      // bycicleMin = '<span class="number">' + bycicleTime % 60 + '</span>분'

      // // 거리와 도보 시간, 자전거 시간을 가지고 HTML Content를 만들어 리턴합니다
      // let content = '<ul class="dotOverlay distanceInfo">';
      // content += '    <li>';
      // content += '        <span class="label">총거리</span><span class="number">' + distance + '</span>m';
      // content += '    </li>';
      // content += '    <li>';
      // content += '        <span class="label">도보</span>' + walkHour + walkMin;
      // content += '    </li>';
      // content += '    <li>';
      // content += '        <span class="label">자전거</span>' + bycicleHour + bycicleMin;
      // content += '    </li>';
      // content += '</ul>'

      // return content;
    },

    showDistance(content, position) {
      if (this.lineData.distanceOverlay) {
        // 커스텀오버레이가 생성된 상태이면

        // 커스텀 오버레이의 위치와 표시할 내용을 설정합니다
        this.lineData.distanceOverlay.setPosition(position);
        this.lineData.distanceOverlay.setContent(content);
      } else {
        // 커스텀 오버레이가 생성되지 않은 상태이면
        // 커스텀 오버레이를 생성하고 지도에 표시합니다
        this.lineData.distanceOverlay = new kakao.maps.CustomOverlay({
          map: this.mapObject, // 커스텀오버레이를 표시할 지도입니다
          content: content, // 커스텀오버레이에 표시할 내용입니다
          position: position, // 커스텀오버레이를 표시할 위치입니다.
          xAnchor: 0,
          yAnchor: 0,
          zIndex: 200,
        });
      }
    },

    // 두 지점 거리 구하기
    getDistance(pointA, pointB) {
      try {
        const pointList = [];
        pointList.push(new kakao.maps.LatLng(pointA.lat, pointA.lng));
        pointList.push(new kakao.maps.LatLng(pointB.lat, pointB.lng));

        const linePoly = new kakao.maps.Polyline({
          map: this.mapObject, // 선을 표시할 지도입니다
          path: [pointList], // 선을 구성하는 좌표 배열입니다 클릭한 위치를 넣어줍니다
          strokeWeight: 1, // 선의 두께입니다
          strokeColor: '#db4040', // 선의 색깔입니다
          strokeOpacity: 1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
          strokeStyle: 'solid', // 선의 스타일입니다
        });

        linePoly.setMap(null);
        return linePoly.getLength();
      } catch (err) {
        console.error(err);
      }
    },

    /**
     * 마커 레이어 생성
     */
    createMarkerLayer() {
      return new MarkerLayer({
        kakao,
        mapObject: this.mapObject,
      });
    },

    /**
     * 원 레이어 생성
     */
    createCircleLayer() {
      return new CircleLayer({
        kakao,
        mapObject: this.mapObject,
      });
    },

    /**
     *
     */
    // createSelectBox(markerList, geometry) {
    //   this.$refs.makeCustomOverlay(markerList, geometry);
    // },
  },
};
