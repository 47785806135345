var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dkgis-wrap-kakao" }, [
    _c(
      "div",
      { ref: "vueDkgisContainer", staticClass: "vue-dkgis-container" },
      [
        _vm._l(_vm.markerData, function(marker) {
          return _c("dk-marker", {
            key: marker.properties.fcltId,
            ref: "fcltMarker",
            refInFor: true,
            attrs: {
              data: marker,
              mapObject: _vm.mapObject,
              geometry: marker.geometry,
              properties: marker.properties,
              draggable: marker.draggable,
              alwaysActivate: marker.alwaysActivate,
              iwContent: marker.iwContent,
              dataObj: marker,
              markerInfoWindow: _vm.markerInfoWindow
            },
            on: { click: _vm.clickMarker }
          })
        }),
        _vm._l(_vm.clusterData, function(cluster, key) {
          return _c("dk-cluster", {
            key: cluster.key || key,
            ref: "cluster",
            refInFor: true,
            attrs: {
              data: cluster,
              mapObject: _vm.mapObject,
              geometry: cluster.geometry,
              properties: cluster.properties,
              dataObj: cluster
            },
            on: { click: _vm.clickCluster }
          })
        }),
        _vm._l(_vm.searchData, function(place, key) {
          return _c("dk-search-place", {
            key: key,
            ref: "place",
            refInFor: true,
            attrs: {
              data: place,
              mapObject: _vm.mapObject,
              geometry: place,
              properties: place,
              dataObj: place
            },
            on: { click: _vm.clickPlace }
          })
        }),
        _vm._l(_vm.selectBoxData, function(sbData) {
          return _c("select-box", {
            key: sbData.key,
            ref: "selectBox",
            refInFor: true,
            attrs: { mapObject: _vm.mapObject, selectBoxData: sbData },
            on: { selectMarker: _vm.selectMarker }
          })
        })
      ],
      2
    ),
    _c("div", {
      ref: "vueDkgisRoadviewContainer",
      class: [_vm.isRoadview ? "show" : "", "vue-dkgis-roadview-container"]
    }),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isRoadview,
            expression: "isRoadview"
          }
        ],
        staticClass: "roadview-button-wrap"
      },
      [
        _c(
          "el-button",
          {
            attrs: { size: "mini" },
            on: {
              click: function($event) {
                _vm.isRoadview = false
              }
            }
          },
          [
            _c("i", {
              staticClass: "fas fa-times",
              staticStyle: { "font-size": "20px" }
            })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }