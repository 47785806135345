<template></template>

<script>
import dkMarker from '../dkGisMarker';

export default {
  mixins: [dkMarker],

  data() {
    return {
      zoom: 0,
    };
  },
  created() {
    this.gisMarkerData = this.$dkPlugin.getGisMarker(this.properties.fcltTypeId);
    if (this.gisMarkerData) {
      this.gisMarkerIconNormal = new kakao.maps.MarkerImage(
        this.gisMarkerData.gisMarkerImage.normal,
        new kakao.maps.Size(this.gisMarkerData.gisMarkerWidth, this.gisMarkerData.gisMarkerHeight)
      );
      this.gisMarkerIconBreak = new kakao.maps.MarkerImage(
        this.gisMarkerData.gisMarkerImage.break,
        new kakao.maps.Size(this.gisMarkerData.gisMarkerWidth, this.gisMarkerData.gisMarkerHeight)
      );
      this.gisMarkerIconRepair = new kakao.maps.MarkerImage(
        this.gisMarkerData.gisMarkerImage.repair,
        new kakao.maps.Size(this.gisMarkerData.gisMarkerWidth, this.gisMarkerData.gisMarkerHeight)
      );
      this.gisMarkerIconSelected = new kakao.maps.MarkerImage(
        this.gisMarkerData.gisMarkerImage.selected,
        new kakao.maps.Size(this.gisMarkerData.gisMarkerWidth, this.gisMarkerData.gisMarkerHeight)
      );
    }
  },

  mounted() {
    const me = this;
    var markerPosition = new kakao.maps.LatLng(this.geometry.lat, this.geometry.lng);
    let markerImg = this.gisMarkerIconNormal;
    switch (this.status) {
      case 1:
        markerImg = this.gisMarkerIconNormal;
        break;
      case 2:
        markerImg = this.gisMarkerIconBreak;
        break;
      case 3:
        markerImg = this.gisMarkerIconRepair;
        break;
      default:
        markerImg = this.gisMarkerIconNormal;
        break;
    }

    this.$marker = new kakao.maps.Marker({
      position: markerPosition,
      draggable: this.draggable,
      map: this.mapObject,
      // image: this.gisMarkerIconNormal,
      image: markerImg,
      zIndex: 10,
    });

    // let infoWindowOffsetY = 70;
    // if (this.gisMarkerData && this.gisMarkerData.gisMarkerHeight) {
    //   infoWindowOffsetY = 70 - (35 - this.gisMarkerData.gisMarkerHeight);
    // }

    // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
    if (this.markerInfoWindow) {
      // const infowindowWidth = this.properties.fcltName.length * 12;
      // //var iwContent = `<div class="infowindow-position" style="margin-top:-${infoWindowOffsetY}px; width:${infowindowWidth}px;">
      // var iwContent = `<div class="infowindow-position" >
      //           ${this.properties.fcltName}
      //       <div>`;
      // if (this.iwContent) {
      //   iwContent = this.iwContent;
      // }

      let infoWindowOffsetY = 70;
      if (this.gisMarkerData && this.gisMarkerData.gisMarkerHeight) {
        infoWindowOffsetY = 70 - (35 - this.gisMarkerData.gisMarkerHeight);
      }
      // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
      var iwContent = `<div class="el-tooltip__popper is-dark infowindow-position" style="margin-top:-${infoWindowOffsetY}px">
                ${this.properties.fcltName}
            <div>`;
      if (this.iwContent) {
        iwContent = this.iwContent;
      }

      // 인포윈도우를 생성합니다
      this.$infowindow = new kakao.maps.CustomOverlay({
        map: this.mapObject,
        position: new kakao.maps.LatLng(this.geometry.lat, this.geometry.lng),
        content: iwContent,
        xAnchor: 1,
        yAnchor: 1,
        zIndex: 15,
      });

      // this.alwaysActivate = !this.isLabelView;
      let isOpen = false;
      if (this.alwaysActivate) {
        isOpen = true;
      } else if (this.isOpenInfoWindow) {
        isOpen = true;
      }
      this.$infowindow.setVisible(isOpen);
    }
    // this.$areaCircle = new kakao.maps.Circle({
    //   map: this.mapObject,
    //   center: new kakao.maps.LatLng(this.geometry.lat, this.geometry.lng),
    //   radius: 50,
    //   strokeWeight: 2,
    //   strokeColor: '#95a5a6',
    //   strokeOpacity: 0.5,
    //   strokeStyle: 'dashed',
    //   fillColor: '#3498db',
    //   fillOpacity: 0.3,
    //   zIndex: 10,
    // });
    // this.$areaCircle.setMap(this.mapObject);

    this.setEvents();
    this.setEventBind();
    this.dataObj.$markerClickEvent = this.$eventFunc['click'];

    // if(this.alwaysActivate) {
    //   this.showInfoWindow();
    // }

    // this.$nextTick(() => {
    //   console.log(me.dataObj);
    //   setTimeout(() => {
    //     if (!me.alwaysActivate) {
    //       // me.$infowindow.setVisible(false);
    //     } else if (me.dataObj.zoomLevel <= 3) {
    //       me.$infowindow.setVisible(true);
    //     } else {
    //       // me.$infowindow.setVisible(me.alwaysActivate || false);
    //       me.$infowindow.setVisible(true);
    //     }
    //   }, 200);
    // });
  },
  destroyed() {
    this.$infowindow.setMap(null);
    this.$marker.setMap(null);
  },
  watch: {
    geometry(newVal, oldVal) {
      var markerPosition = new kakao.maps.LatLng(newVal.lat, newVal.lng);
      this.$marker.setPosition(markerPosition);
    },
    status(newVal, oldVal) {
      switch (newVal) {
        case 1:
          this.setIconNormal();
          break;
        case 2:
          this.setIconBreak();
          break;
        case 3:
          this.setIconRepair();
          break;
        default:
          this.setIconNormal();
          break;
      }
    },
    // zoomLevel(newVal, oldVal) {
    //   console.log(newVal);
    // },
    isOpenInfoWindow(newVal) {
      if (this.alwaysActivate) {
        this.$infowindow.setVisible(true);
      } else {
        if (this.isOpenInfoWindow) {
          this.$infowindow.setVisible(true);
        } else {
          this.$infowindow.setVisible(false);
        }
      }
    },
  },
  methods: {
    /**
     * 카카오는 이벤트를 삭제할 때 함수포인터를 던져야 하므로
     * 이벤트 함수 목록을 미리 정의한다
     */
    // showInfoWindow() {
    //   this.alwaysActivate = true;
    //   this.$infowindow.setVisible(true);
    // },
    // closeInfoWindow() {
    //   this.alwaysActivate = false;
    //   this.$infowindow.setVisible(false);
    // },

    setEvents() {
      var me = this;
      me.$eventFunc = {};
      me.$events.forEach((event) => {
        // 인포윈도우 열기
        if (event == 'mouseover') {
          if (!this.markerInfoWindow) {
            return;
          }

          me.$eventFunc.mouseover = function (ev) {
            if (me.alwaysActivate) {
              return;
            }
            if (me.isOpenInfoWindow) {
              return;
            }

            me.$infowindow.setVisible(true);
            if (me.$listeners['mouseover']) {
              me.$emit('mouseover', {
                event: ev,
                properties: me.properties,
              });
            }
          };
        }
        // 인포윈도우 닫기
        else if (event == 'mouseout') {
          if (!this.markerInfoWindow) {
            return;
          }

          me.$eventFunc.mouseout = function (ev) {
            if (me.alwaysActivate) {
              return;
            }
            if (me.isOpenInfoWindow) {
              return;
            }

            me.$infowindow.setVisible(false);
            if (me.$listeners['mouseout']) {
              me.$emit('mouseout', {
                event: ev,
                properties: me.properties,
              });
            }
          };
        }
        // 드래그 이벤트 활성화 되있을시, dragend 이벤트 처리
        else if (event == 'dragend' && this.draggable) {
          // 애비에게 dragend 전파함
          me.$eventFunc[event] = function (ev) {
            me.$parent.$emit('markerDragEnd', {
              latLng: me.$marker.getPosition(),
              properties: me.properties,
            });
            me.$emit(event, {
              event: ev,
              properties: me.properties,
            });
          };
        } else if (event == 'click') {
          me.$eventFunc[event] = function (ev) {
            me.$marker.setImage(me.gisMarkerIconSelected);
            me.$emit(event, {
              event: ev,
              properties: me.properties,
              object: me,
            });
          };
        } else if (me.$listeners[event]) {
          me.$eventFunc[event] = function (ev) {
            me.$emit(event, {
              event: ev,
              properties: me.properties,
            });
          };
        }
      });
    },
    setEventBind() {
      var me = this;
      Object.keys(me.$eventFunc).map((event) => {
        kakao.maps.event.addListener(me.$marker, event, me.$eventFunc[event]);
      });
    },
    setUnEventBind() {
      var me = this;
      Object.keys(me.$eventFunc).map((event) => {
        kakao.maps.event.removeListener(me.$marker, event, me.$eventFunc[event]);
      });
    },
    setIconImageByStatus() {
      switch (this.status) {
        case 1:
          this.setIconNormal();
          break;
        case 2:
          this.setIconBreak();
          break;
        case 3:
          this.setIconRepair();
          break;
        default:
          this.setIconNormal();
          break;
      }
    },
    setIconNormal() {
      this.$marker.setImage(this.gisMarkerIconNormal);
    },
    setIconBreak() {
      this.$marker.setImage(this.gisMarkerIconBreak);
    },
    setIconRepair() {
      this.$marker.setImage(this.gisMarkerIconRepair);
    },
  },
};
</script>
