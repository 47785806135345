var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "content",
      staticClass: "marker-select-box-info",
      style:
        "z-index: 10000; width: " +
        _vm.width +
        "px; height:" +
        _vm.height +
        "px"
    },
    [
      _c(
        "div",
        { staticClass: "marker-select-box" },
        _vm._l(_vm.selectBoxData.markerList, function(markerInfo) {
          return _c(
            "div",
            {
              key: markerInfo.key,
              staticClass: "marker-select-element",
              on: {
                click: function($event) {
                  return _vm.selectMarker(markerInfo)
                }
              }
            },
            [_c("strong", [_vm._v(_vm._s(markerInfo.name || markerInfo.key))])]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }