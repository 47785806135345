import proj4 from 'proj4'

export default {
    data() {
        return {
            EPSG4326:"EPSG:4326"
        }
    },
    methods : {
        // 이걸쓰면 결과객체가 튀어나와야됨
        convertProj(from, to, coordinates) {

        }
    }
}