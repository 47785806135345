<template>
  <!-- <div ref="clusterTag" class="cluster-marker" @click="clusterClick" @mouseenter="clusterMouseover" @mouseleave="clusterMouseout">
    <span class="title">{{ properties.name }}</span>
    <span class="fclt-count">{{ count }}</span>
  </div> -->
</template>

<script>
import dkCluster from '../dkGisCluster';
import axios from 'axios';

export default {
  mixins: [dkCluster],
  data() {
    return {
      key: '',
      clusterOverlay: null,
      overlayPolygon: [],
      polygonPath: [],
      count: 0,
    };
  },
  created() {
    if (this.properties) {
      this.key = 'cluster_' + this.properties.clusterCd;
    }

    // // 커스텀 오버레이가 표시될 위치입니다
    // var position = new kakao.maps.LatLng(this.geometry.lat, this.geometry.lng);
    // // var content = this.$refs.clusterTag;
    // let content = this.makeContent();

    // // 커스텀 오버레이를 생성합니다
    // this.clusterOverlay = new kakao.maps.CustomOverlay({
    //   map: this.mapObject,
    //   position: position,
    //   content: content,
    //   xAnchor: 1,
    //   yAnchor: 1,
    //   clickable: true,
    // });

    this.CustomOverlay();

    // this.addEventHandle(this.key, 'mousedown', this.clusterClick);

    this.overlayPolygon = new kakao.maps.Polygon({
      map: this.mapObject,
      path: [],
      strokeWeight: 2,
      strokeColor: '#47b6e4',
      strokeOpacity: 0.8,
      strokeStyle: 'solid',
      fillColor: '#47b6e4',
      fillOpacity: 0.4,
    });
    // this.dataObj.$clusterClickEvent = this.clusterClick;
    // this.dataObj.$clusterFocusEvent = this.clusterMouseover;
    // this.dataObj.$clusterBlurEvent = this.clusterMouseout;
    window['clusterClick' + this.key] = this.clusterClick;
    window['clusterOver' + this.key] = this.clusterMouseover;
    window['clusterOut' + this.key] = this.clusterMouseout;
  },
  // computed: {
  //   count() {
  //     return this.properties.count ? this.properties.count : 0;
  //   },
  // },
  watch: {
    properties(n, o) {
      this.count = n.count ? n.count : 0;
      this.CustomOverlay();
    },
  },
  mounted() {
    const me = this;

    // if (this.properties) {
    //   this.key = 'cluster_' + this.properties.clusterCd;
    // }

    // 커스텀 오버레이가 표시될 위치입니다
    var position = new kakao.maps.LatLng(this.geometry.lat, this.geometry.lng);
    // var content = this.$refs.clusterTag;
    // let content = this.makeContent();
    // // 커스텀 오버레이를 생성합니다
    // this.clusterOverlay = new kakao.maps.CustomOverlay({
    //   map: this.mapObject,
    //   position: position,
    //   content: content,
    //   xAnchor: 1,
    //   yAnchor: 1,
    //   clickable: true,
    // });

    this.overlayPolygon = new kakao.maps.Polygon({
      map: this.mapObject,
      path: [],
      strokeWeight: 2,
      strokeColor: '#47b6e4',
      strokeOpacity: 0.8,
      strokeStyle: 'solid',
      fillColor: '#47b6e4',
      fillOpacity: 0.4,
    });
    kakao.maps.event.addListener(this.overlayPolygon, 'mousedown', this.clusterMouseout);

    // this.dataObj.$clusterClickEvent = this.clusterClick;
    // this.dataObj.$clusterFocusEvent = this.clusterMouseover;
    // this.dataObj.$clusterBlurEvent = this.clusterMouseout;
    // kakao.maps.event.addListener(this.clusterOverlay, 'click', this.clusterClick);
    let geojsonFile = `/geojson/${this.properties.clusterCd}.geojson`;
    this.polygonPath = [];
    axios
      .get(geojsonFile)
      .then(({ data }) => {
        if (data.type == 'MultiPolygon') {
          data.coordinates[0][0].forEach((path) => {
            // this.polygonPath.push(new kakao.maps.LatLng(path[1], path[0]));
            me.polygonPath.push(new kakao.maps.LatLng(path[1], path[0]));
          });
        }
      })
      .catch((err) => {});

    // this.$nextTick(() => {
    //   this.addEventHandle(this.key, 'mousedown', this.clusterClick);
    // });
    // this.CustomOverlay();
  },
  destroyed() {
    this.clusterOverlay.setMap(null);
    this.clusterOverlay = null;

    this.overlayPolygon.setMap(null);
    this.overlayPolygon = null;
    this.polygonPath = [];
  },
  methods: {
    CustomOverlay() {
      if (this.clusterOverlay) {
        this.clusterOverlay.setMap(null);
        this.clusterOverlay = undefined;
      }
      // 커스텀 오버레이가 표시될 위치입니다
      const position = new kakao.maps.LatLng(this.geometry.lat, this.geometry.lng);
      // var content = this.$refs.clusterTag;
      const content = this.makeContent();

      this.clusterOverlay = new kakao.maps.CustomOverlay({
        map: this.mapObject,
        position: position,
        content: content,
        xAnchor: 1,
        yAnchor: 1,
        clickable: true,
      });
    },

    clusterClick(ev) {
      let me = this;
      me.clusterMouseout();
      me.$emit('click', {
        event: ev || null,
        geometry: me.geometry,
        properties: me.properties,
        object: me,
      });
    },
    clusterMouseover() {
      this.overlayPolygon.setMap(null);
      this.overlayPolygon.setPath(this.polygonPath);
      this.overlayPolygon.setMap(this.mapObject);

      // })
    },
    clusterMouseout() {
      this.overlayPolygon.setMap(null);
    },
    makeContent() {
      const clickName = 'clusterClick' + this.key;
      const overName = 'clusterOver' + this.key;
      const outName = 'clusterOut' + this.key;
      return `<div id="${this.key}" class="cluster-marker" onclick="${clickName}()" onmouseover="${overName}()" onmouseout="${outName}()" >
        <span class="title">${this.properties.name}</span>
        <span class="fclt-count">${this.properties.count}</span>
      </div>
      `;
    },

    // target node에 이벤트 핸들러를 등록하는 함수힙니다
    async addEventHandle(targetId, type, callback) {
      const target = document.getElementById(targetId);
      if (!target) {
        await this.sleep(3000);
        if (!target) return;
      }

      // if (target.addEventListener) {
      target.addEventListener(type, callback);
      // } else {
      //   target.attachEvent('on' + type, callback);
      // }
    },

    sleep(time) {
      return new Promise((resolve) => {
        setTimeout(() => resolve(), time);
      });
    },
  },
};
</script>
